import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IContractLineExtension {
    contractLineID: string;
    supplierRef: string;
    description: string;
    productGroup: string;
}

export class ContractLineExtension implements IContractLineExtension {

    constructor(data?: IContractLineExtension) {
        if (data) this.update(data);
    }

    update(data: IContractLineExtension): void {
        mapData(data, { root: () => this });
    }

    contractLineID: string = utils.emptyGuidValue;
    supplierRef: string = "";
    description: string = "";
    productGroup: string = "";
}
