// set up as VM object for instances where extra data is provided with the imported contracts

import { IContract, Contract } from "../../Contract";
import { mapData } from "@/utilities/DataMapping";

export interface IContractDetailVM {
    contract: IContract;
}

export class ContractDetailVM implements IContractDetailVM {

    constructor(data?: IContractDetailVM) {
        if (data) this.update(data);
    }

    update(data: IContractDetailVM): void {
        mapData(data, { 
            root: () => this,
            contract: () => new Contract()
        });
    }

    contract: Contract = new Contract();
}
