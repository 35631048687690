



















































































































































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { Address, IAddress } from "@/model/Address";
import { ContractDetailVM } from "@/model/Api/VM/ContractDetailVM";
import { Country, ICountry } from "@/model/Country";
import { Order } from "@/model/Order";
import { Ref, Watch } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class OrderDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("orderForm") private readonly orderForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private addresses: Array<Address> = [];
    private countries: Array<Country> = [];
    private contractData: ContractDetailVM = new ContractDetailVM();
    private order: Order = new Order();

    isLoading = false;
    selectedInvoiceAddress = 0;
    selectedDeliveryAddress = 0;

    //
    // -- Watchers
    //

    @Watch("selectedInvoiceAddress")
    private onInvoiceNameChanged() {
        if (this.isLoading) return;
        if (this.selectedInvoiceAddress == 0) return;

        let address = new Address();
        this.addresses.forEach(a => { 
            if (a.id == this.selectedInvoiceAddress) {
                address = a;
            }
        });
        this.order.invoiceName = address.name;
        this.order.invoiceAddress1 = address.address1;
        this.order.invoiceAddress2 = address.address2;
        this.order.invoiceAddress3 = address.address3;
        this.order.invoiceAddress4 = address.address4;
        this.order.invoiceAddress5 = address.address5;
        this.order.invoiceCountry = address.country;
        this.order.supplierContact = address.contact;
        this.order.supplierTelephone = address.phone;
        this.order.supplierEmail = address.email;
    }

    @Watch("selectedDeliveryAddress")
    private onDeliveryNameChanged() {
        if (this.isLoading) return;
        if (this.selectedDeliveryAddress == 0) return;

        let address = new Address();
        this.addresses.forEach(a => { 
            if (a.id == this.selectedDeliveryAddress) {
                address = a;
            }
        });
        this.order.deliveryName = address.name;
        this.order.deliveryAddress1 = address.address1;
        this.order.deliveryAddress2 = address.address2;
        this.order.deliveryAddress3 = address.address3;
        this.order.deliveryAddress4 = address.address4;
        this.order.deliveryAddress5 = address.address5;
        this.order.deliveryCountry = address.country;
        this.order.deliveryContact = address.contact;
        this.order.deliveryTelephone = address.phone;
        this.order.deliveryEmail = address.email;
    }

    //
    // -- methods
    //

    async open(id: string): Promise<void> {
        this.reset();
        this.isLoading = true;
        
        await this.loadContractData(id);
        await this.loadSupplierAddresses();
        await this.loadDeliveryCountries();

        this.order.contractID = id;
        this.order.supplierID = this.contractData.contract.supplierID;

        this.dialogueTitle = "Order Details: " + this.contractData.contract.poNumber;

        this.isLoading = false;

        this.showDialogue = true;
    }

    async edit(orderID: number, contractID: string): Promise<void> {
        this.reset();
        this.isLoading = true;

        await this.loadOrder(orderID);
        await this.loadContractData(contractID);
        await this.loadSupplierAddresses();
        await this.loadDeliveryCountries();

        this.isLoading = false;
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.contractData);
        utils.resetObject(this.order);
        this.addresses = [];
        this.countries = [];
        this.selectedInvoiceAddress = 0;
        this.selectedDeliveryAddress = 0;
        this.orderForm?.resetValidation();
    }

    resetInvoiceAddress(): void {
        this.isLoading = true;

        this.selectedInvoiceAddress = 0;
        this.order.invoiceName = "";
        this.order.invoiceAddress1 = "";
        this.order.invoiceAddress2 = "";
        this.order.invoiceAddress3 = "";
        this.order.invoiceAddress4 = "";
        this.order.invoiceAddress5 = "";
        this.order.invoiceCountry = "";
        this.order.supplierContact = "";
        this.order.supplierEmail = "";
        this.order.supplierTelephone = "";

        this.isLoading = false;
        this.orderForm?.resetValidation();
    }

    resetFactoryAddress(): void {
        this.isLoading = true;
        
        this.selectedDeliveryAddress = 0;
        this.order.deliveryName = "";
        this.order.deliveryAddress1 = "";
        this.order.deliveryAddress2 = "";
        this.order.deliveryAddress3 = "";
        this.order.deliveryAddress4 = "";
        this.order.deliveryAddress5 = "";
        this.order.deliveryCountry = "";
        this.order.deliveryContact = "";
        this.order.deliveryEmail = "";
        this.order.deliveryTelephone = "";

        this.isLoading = false;
        this.orderForm?.resetValidation();
    }

    setDeliveryContact(): void {
        this.order.deliveryContact = this.order.supplierContact;
    }

    setDeliveryContactEmail(): void {
        this.order.deliveryEmail = this.order.supplierEmail;
    }

    setDeliveryContactTelephone(): void {
        this.order.deliveryTelephone = this.order.supplierTelephone;
    }

    private async save() {
        if (!this.orderForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response = await apiClient.post("/api/order/save", this.order, "save-order");
        toastr.success("Saved"); 
        if (this.order.id == 0) {
            this.$router.push("/order/" + response);
        } else {
            this.$emit("load", this.order.id);
        }
        this.showDialogue = false;
    }

    private async loadOrder(id: number) {
        const retVal = await apiClient.get(`/api/order/load?id=${id}`);
        this.order.update(retVal);
    }

    private async loadContractData(id: string) {
        const retVal = await apiClient.get(`/api/contract/loadContractDetails?id=${id}`);
        this.contractData.update(retVal);
    }

    private async loadSupplierAddresses() {
        this.addresses = [];
        const response = await apiClient.get(`/api/supplier/loadAddresses?id=${this.contractData.contract.supplierID}`);
        this.addresses.push(...response.map((a: IAddress) => new Address(a)));
    }

    private async loadDeliveryCountries() {
        this.countries = [];
        const response = await apiClient.get("/api/country/deliveryCountries");
        this.countries.push(...response.map((c: ICountry) => new Country(c)));
    }
}
