import { IContractExtension, ContractExtension } from "@/model/ContractExtension";
import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IContract {
    id: string;
    retailerID: string;
    supplierID: string;
    poNumber: string;
    description: string;
    contractCreatedDate: Date;
    importedDate: Date;
    updatedDate: Date;
    deletedDate: Date;
    extension: IContractExtension;
}

export class Contract implements IContract {

    constructor(data?: IContract) {
        if (data) this.update(data);
    }

    update(data: IContract): void {
        mapData(data, { 
            root: () => this,
            extension: () => new ContractExtension()
         });
    }

    id: string = utils.emptyGuidValue;
    retailerID: string = utils.emptyGuidValue;
    supplierID: string = utils.emptyGuidValue;
    poNumber: string = "";
    description: string = "";
    contractCreatedDate: Date = new Date(utils.emptyDateValue);
    importedDate: Date = new Date(utils.emptyDateValue);
    updatedDate: Date = new Date(utils.emptyDateValue);
    deletedDate: Date = new Date(utils.emptyDateValue);
    extension: ContractExtension = new ContractExtension();
}
