import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrder {
    id: number;
    contractID: string;
    supplierID: string;
    supplierContact: string;
    supplierEmail: string;
    supplierTelephone: string;
    invoiceName: string;
    invoiceAddress1: string;
    invoiceAddress2: string;
    invoiceAddress3: string;
    invoiceAddress4: string;
    invoiceAddress5: string;
    invoiceCountry: string;
    deliveryContact: string;
    deliveryEmail: string;
    deliveryTelephone: string;
    deliveryName: string;
    deliveryAddress1: string;
    deliveryAddress2: string;
    deliveryAddress3: string;
    deliveryAddress4: string;
    deliveryAddress5: string;
    deliveryCountry: string;
    countryWhereRequiredID: string;
    confirmedDate: Date;
    confirmedDateLocal: Date;
    rfdDate: Date;
    rfdReasonID: number;
    dispatchedDate: Date;
    createdDate: Date;
    createdByUserID: string;
}

export class Order implements IOrder {

    constructor(data?: IOrder) {
        if (data) this.update(data);
    }

    update(data: IOrder): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    contractID: string = utils.emptyGuidValue;
    supplierID: string = utils.emptyGuidValue;
    supplierContact: string = "";
    supplierEmail: string = "";
    supplierTelephone: string = "";
    invoiceName: string = "";
    invoiceAddress1: string = "";
    invoiceAddress2: string = "";
    invoiceAddress3: string = "";
    invoiceAddress4: string = "";
    invoiceAddress5: string = "";
    invoiceCountry: string = "";
    deliveryContact: string = "";
    deliveryEmail: string = "";
    deliveryTelephone: string = "";
    deliveryName: string = "";
    deliveryAddress1: string = "";
    deliveryAddress2: string = "";
    deliveryAddress3: string = "";
    deliveryAddress4: string = "";
    deliveryAddress5: string = "";
    deliveryCountry: string = "";
    countryWhereRequiredID: string = utils.emptyGuidValue;
    confirmedDate: Date = new Date(utils.emptyDateValue);
    confirmedDateLocal: Date = new Date(utils.emptyDateValue);
    rfdDate: Date = new Date(utils.emptyDateValue);
    rfdReasonID: number = 0;
    dispatchedDate: Date = new Date(utils.emptyDateValue);
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
}