import { IContractLineExtension, ContractLineExtension } from "@/model/ContractLineExtension";
import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IContractLine {
    id: string;
    contractID: string;
    description: string;
    colour: string;
    size: string;
    quantity: number;
    barcode: string;
    sku: string;
    lineNumber: number;
    extension: IContractLineExtension;
}

export class ContractLine implements IContractLine {

    constructor(data?: IContractLine) {
        if (data) this.update(data);
    }

    update(data: IContractLine): void {
        mapData(data, { 
            root: () => this,
            extension: () => new ContractLineExtension()
        });
    }

    id: string = utils.emptyGuidValue;
    contractID: string = utils.emptyGuidValue;
    description: string = "";
    colour: string = "";
    size: string = "";
    quantity: number = 0;
    barcode: string = "";
    sku: string = "";
    lineNumber: number = 0;
    extension: ContractLineExtension = new ContractLineExtension();
}
