import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IContractExtension {
    contractID: string;
}

export class ContractExtension implements IContractExtension {

    constructor(data?: IContractExtension) {
        if (data) this.update(data);
    }

    update(data: IContractExtension): void {
        mapData(data, { root: () => this });
    }

    contractID: string = utils.emptyGuidValue;
}
